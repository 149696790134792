<template>
    <div style="background-color: #F7F7F7;padding: 20px;min-height: 100vh">
      <div class="padding-16 mb-10" style="background-color: #FFFFFF">
        <b class="fs-16">{{$route.query.name}}</b>
        <p class="mt-16 mb-12 fs-14" ><span class="mr-18" style="color: #999999">项目执行阶段</span><span>{{stepName}}</span></p>
        <div class="display-flex ai-center">
          <div style="width: 100px;color: #999999 ;font-size: 14px"> 项目执行进度  </div>
          <Progress  v-if="progress===1" :percent="100" />
          <Progress  v-else-if="progress===0" :percent="0" />
          <Progress v-else  :percent="progress*100" />
        </div>
      </div>
      <div style="background-color: #FFFFFF;padding-top: 20px;padding-left: 14px">
        <Timeline>
          <Timeline-item v-for="item in list" :key="item.id">
               <b class="fs-16 ">{{item.stepName}}</b><br/>
               <div class="mt-14" style="color: #555555">{{item.creator}}：{{item.description}}</div>
                <div class="mt-10 fs-12" style="color: #999999 ">{{item.createTime}}</div>
          </Timeline-item>
        </Timeline>
      </div>
    </div>
</template>

<script>
export default {
  name: 'followInfo',
  components: {},
  props: {},
  data () {
    return {
      stepName: '',
      list: []
    }
  },
  filters: {},
  computed: {

  },
  watch: {},
  methods: {},
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {
    this.$api.follow.info({ projectId: this.$route.query.projectId }).then(res => {
      console.log(res)
      this.stepName = res.stepName
      this.progress = res.progress
      this.list = res.projectFollowHistoryVOS
    })
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
/deep/
.ivu-timeline-item-content {
  top: -5px!important;
}
/deep/
.ivu-timeline{
  .ivu-timeline-item{
      &:first-child{
      .ivu-timeline-item-head{
        background-color: #44C455!important;
        border: none;}
    }
  }
  .ivu-timeline-item{
    .ivu-timeline-item-head{
      background-color:#3377FF!important;
      border: none;
    }

  }
}
/deep/
.ivu-timeline-item-tail {
  height: 100%;
  width: 1px;
  border-left: 1px dashed #e8eaec;
  position: absolute;
  left: 6px;
  top: 0;
  background: #FFFFFF;
}
/deep/
.ivu-progress{
   width: 200px!important;

}
/deep/
.ivu-progress-bg{
  height: 8px!important;
}
</style>
